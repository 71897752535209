import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';

// Componente per wrappare il job in un popup
@Component({
  selector: 'ta-job-wrapper',
  standalone: true,
  template: `
    <ta-dialog [visible]="this.visible()" [showHeader]="false" bodyCssClass="tw-size-full" [dialogCssClass]="this.dialogCssClass()"
    >
      <ng-content></ng-content>
    </ta-dialog>
  `,
  imports: [DialogComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobWrapperComponent {

  public visible = input(false);
  public dialogCssClass = input('tw-h-full 3xl:tw-h-240 tw-w-full lg:tw-w-360');

}
